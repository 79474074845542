define("discourse/plugins/discourse-kolide/discourse/components/kolide-devices-dropdown", ["exports", "@ember-decorators/component", "discourse-common/utils/decorators", "select-kit/components/dropdown-select-box", "select-kit/components/select-kit"], function (_exports, _component, _decorators, _dropdownSelectBox, _selectKit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const KolideDevicesDropdown = dt7948.c(class KolideDevicesDropdown extends _dropdownSelectBox.default {
    content(devices) {
      return devices.map(device => {
        return {
          id: device.id,
          title: device.name,
          description: device.hardware_model,
          icon: device.is_orphan ? "question" : "user"
        };
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "content", [(0, _decorators.default)("devices")]))();
  }, [(0, _component.classNames)("kolide-devices-dropdown"), (0, _selectKit.selectKitOptions)({
    icon: null,
    showCaret: true,
    none: "discourse_kolide.onboarding.select_device",
    showFullTitle: true
  }), (0, _selectKit.pluginApiIdentifiers)("kolide-devices-dropdown")]);
  var _default = _exports.default = KolideDevicesDropdown;
});